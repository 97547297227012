import React from 'react'
import icon1 from './../assets/images/streamlined_icon_90830b2f29.svg'
import icon2 from './../assets/images/CUSTOMIZABLE_ICON_46b12793b1.svg'
import icon3 from './../assets/images/intergrate_73e312e015.svg'
import icon4 from './../assets/images/acounting_icon_76c12aaffc.svg'
import icon5 from './../assets/images/composable_icon_caef9d865e.svg'
import icon6 from './../assets/images/wallet_icon_4b5efb368b.svg'
import icon7 from './../assets/images/multi_audit_icon_465b75d7dc.svg'
import icon8 from './../assets/images/opensource_icon_0791ea6347.svg'
import hero from './../assets/images/code_img_42705eb1a5.png'
import CardTypeDub from './Cards/CardTypeDub'
const Needs = () => {

  return (
    <section className='w-full rounded-3xl '>
        {/* <div className='rounded-t-[50px] bg-customGradient1 bg-no-repeat bg-blend-difference md:p-20 lg:pt-40 lg:p-28' style={{backgroundPosition:'50% 25%', backgroundSize:'50%', backgroundImage:'url(https://www.superfluid.finance/img/backgrounds/blue-glow-bg.png)' }}> */}
            <div className='text-center'>
                <div className='pt-14 lg:py-0'>
                    <h1 className=' font-bold leading-none max-w-[50%] m-auto mb-14 text-customGradient1 text-5xl lg:text-8xl'>
                      {'Made for \n your needs.'}
                    </h1>
                </div>
                <div className='flex flex-col lg:flex-row justify-center gap-[1.5rem] items-stretch px-8 lg:px-40 pb-3'>
                  <CardTypeDub
                    icons={icon1}
                    title={'Streamlined buyer experience'}
                    description={'Enable customers to subscribe with crypto in as few as 3 clicks'}
                  />
                  <CardTypeDub
                    icons={icon2}
                    title={'Highly customizable design'}
                    description={'Enable customers to subscribe with crypto in as few as 3 clicks'}
                  />
                  <CardTypeDub
                    icons={icon3}
                    title={'Easy to integrate'}
                    description={'Enable customers to subscribe with crypto in as few as 3 clicks'}
                  />
                  <CardTypeDub
                    icons={icon4}
                    title={'Simple accounting'}
                    description={'Enable customers to subscribe with crypto in as few as 3 clicks'}
                  />
                </div>
                <div className='flex flex-col lg:flex-row justify-center gap-[1.5rem] items-stretch px-8 lg:px-40 pt-3'>
                  <CardTypeDub
                    icons={icon5}
                    title={'Web3 Composability and NFT Support'}
                    description={'Empowering Web3 with composable NFTs for Interactive and evolving digital assets'}
                  />
                  <CardTypeDub
                    icons={icon6}
                    title={'Full Smart Contract Wallet Support'}
                    description={'Support smart contract wallets for enhanced security and direct DApp interaction'}
                  />
                  <CardTypeDub
                    icons={icon7}
                    title={'Multi-Audited Protocol'}
                    description={'Audited by industry leaders such as Halborn and PeckSchield'}
                  />
                  <CardTypeDub
                    icons={icon8}
                    title={'Free and Open-Source'}
                    description={'Transparent, community driven, and open source protocol'}
                  />
                </div>
            {/* </div> */}
        </div>
        <section className='relative block overflow-hidden  pb-[400px]'>
          {/* <div className='bg-customGradient1 pb-[400px] rounded-b-[50px]'> */}
            <div className='pt-[100px] pb-[50px] text-center space-y-3'>
              <h2 className='text-customGradient1 font-bold mr-auto mb-5 px-5 text-2xl lg:text-6xl'>
                Developer-friendly integration
              </h2>
              {/* <p className='max-w-[600px] text-[#8995a1] font-normal text-xl'> */}
              <p className='text-[#8995a1] font-normal text-2xl'>
                Start accepting subscription with just a few lines of code.
              </p>
            </div>
            <div className='flex justify-center items-center text-left px-2 lg:px-40 space-y-10 mt-[50px]'>
              <div className='px-0 lg:px-32 flex flex-col-reverse lg:flex-row space-x-0 lg:space-x-8 items-center justify-center'>
                <div className='w-full lg:w-1/3 space-y-4 lg:space-y-0 mt-14'>
                  <h2 className='text-customGradient1 font-bold  mb-5  text-xl'>
                  Simple Integration and Customisation
                  </h2>
                  <p className='text-[#838b96] px-5 lg:px-0 text-base lg:text-lg font-normal leading-loose'>
                    Maji Cross-chain Checkout can be seamlessly incorporated into your project using our React component, a straightforward web component,
                    or even by publishing it on IPFS. Plus, you can use the Widget Builder to quickly generate configurations and test them out before going live.
                  </p>
                  <div className='py-10 mb-20 flex flex-col lg:flex-row justify-between text-white'>
                    <a href='https://blocksurvey.io/maji-early-access-dqn91xjKQi6ymmOeMXUrKQ?v=o'>
                        <button className=' border border-custom bg-custom rounded-full px-4 py-3 lg:px-8 lg:py-4 text-center text-sm lg:text-base font-bold w-1/2 lg:w-fit mb-1 lg:mb-0'>
                            REQUEST BETA
                        </button>
                    </a>
                        <button className=' border border-custom rounded-full px-4 py-3 lg:px-8 lg:py-4 text-center text-sm lg:text-base font-bold w-1/3 lg:w-fit text-custom mt-1 lg:mb-0'>
                            Book a Demo
                        </button>
                  </div>
                </div>
                <div className='w-full lg:w-2/3 p-0 lg:pl-14'>
                    <img src={hero} alt='hero' className='w-full' loading='lazy' style={{filter: 'drop-shadow(5px 5px 40px rgba(99,120,231,.5))'}}/>
                </div>
              </div>
            </div>

          {/* </div> */}
        </section>
    </section>
  )
}

export default Needs