import React from 'react'
import { BsDiscord, BsTwitter } from 'react-icons/bs'
import { GrYoutube, GrLinkedinOption, GrMedium } from 'react-icons/gr'

const Footer = () => {
    return (
        // <section className='flex justify-center items-center flex-col py-6 space-y-5'>
        //     <button className='border border-custom rounded-lg text-center font-semibold py-3 px-8'>Book Demo</button>
        //     <div className='p-4'>
        //         <h2 className='text-3xl font-bold'>Sign up for our monthly newsletter</h2>
        //     </div>
        //     <div>
        //         Sign up to the Maji newsletter and stay up to date with Maji Protocol and the innovative products building on top of it.
        //     </div>
        //     <form className='flex justify-center items-center flex-col space-y-5 py-6 w-full'>
        //         <input type='text' placeholder='Your Name*' className='appearance-none border border-gray-500 text-xs rounded-lg w-full lg:w-1/2 p-2' />
        //         <input type='email' placeholder='Your Email*' className='appearance-none border border-gray-500 text-xs rounded-lg w-full lg:w-1/2 p-2' />
        //         <button className='border bg-custom rounded-lg lg:rounded-3xl text-center font-semibold  px-4 py-2 lg:py-3 lg:px-8 sm:text-xs lg:text-base'>Sign Up</button>
        //     </form>
        //     <div className='flex flex-row items-center justify-center space-x-4'>
        //         <div className='bg-black p-2 rounded-md'>
        //             <GrYoutube size={16} color='white' />
        //         </div>
        //         <div className='bg-black  p-2 rounded-md'>
        //             <GrMedium size={16} color='white' />
        //         </div>
        //         <div className='bg-black  p-2 rounded-md'>
        //             <BsDiscord size={16} color='white' />
        //         </div>
        //         <div className='bg-black  p-2 rounded-md'>
        //             <BsTwitter size={16} color='white' />
        //         </div>
        //         <div className='bg-black p-2 rounded-md'>
        //             <GrLinkedinOption size={16} color='white' />
        //         </div>
        //     </div>
        // </section>
        <section className='flex justify-center items-center flex-col py-6 space-y-5'>
            <div className='p-4'>
                <h2 className='text-5xl font-GTWalsheimPro-Bold'>Get Beta Access</h2>
            </div>
            <div className='text-xl'>
                Our waitlist allows us to prioritize our early users and community {'\n'}at a launch with unique features and early access
            </div>
            {/* <form className='flex justify-center items-center flex-col space-y-5 py-6 w-full'>
                <input type='text' placeholder='Your Name*' className='appearance-none border border-gray-500 text-xs rounded-lg w-full lg:w-1/2 p-2' />
                <input type='email' placeholder='Your Email*' className='appearance-none border border-gray-500 text-xs rounded-lg w-full lg:w-1/2 p-2' />
                <button className='border border-custom rounded-lg text-center font-semibold py-3 px-8'>Book Demo</button>
            </form> */}
            <a href='https://blocksurvey.io/maji-early-access-dqn91xjKQi6ymmOeMXUrKQ?v=o' >
                <button className='border bg-custom rounded-lg lg:rounded-3xl text-center font-bold text-slate-200  px-4 py-2 lg:py-3 lg:px-8 sm:text-xs lg:text-base' >REQUEST BETA</button>
            </a>
            <div className='flex flex-row items-center justify-center space-x-4'>
                <div className='bg-black p-2 rounded-md'>
                    <GrYoutube size={16} color='white' />
                </div>
                <div className='bg-black  p-2 rounded-md'>
                    <GrMedium size={16} color='white' />
                </div>
                <div className='bg-black  p-2 rounded-md'>
                    <BsDiscord size={16} color='white' />
                </div>
                <div className='bg-black  p-2 rounded-md'>
                    <BsTwitter size={16} color='white' />
                </div>
                <div className='bg-black p-2 rounded-md'>
                    <GrLinkedinOption size={16} color='white' />
                </div>
            </div>
        </section>
    )
}

export default Footer