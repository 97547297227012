import React from 'react'
import Introduction from './Introduction'
import Usecases from './Usecases'

const Body = () => {
  return (
    <section>
        <Introduction/>
        <Usecases/>
    </section>
  )
}

export default Body