import React from 'react'
import icons1 from './../assets/images/good.svg'
import icons2 from './../assets/images/circle_good.svg'

const Roadmap = () => {
  return (
    <section className='bg-cover' style={{backgroundImage:'url(https://www.superfluid.finance/img/backgrounds/green-glow.png)', backgroundPosition:'50% 85%', backgroundRepeat:'no-repeat'}}>
        <div>
            <div className=''>
                <div className='bg-white rounded-[30px] relative top-[-100px] pt-16 px-5 pb-8 sm:mx-0 md:mx-20 lg:mx-44'>
                    <div className='pb-14'>
                        <h2 className='text-3xl font-black'>Roadmap</h2>
                    </div>
                    <div className='relative ' style={{margin:'0'}}>
                        <div className="border-2 border-custom rounded-full h-8 w-8  bg-white absolute left-0 mt-5"></div>
                        <div className="ml-12 p-4 border-l-2 border-custom border-dashed text-left" >
                            <div className='space-y-2'>
                                <h3 className="font-semibold text-2xl mb-1">Phase 1</h3>
                                <div className='flex flex-col gap-5'>
                                    <div className='rounded-3xl bg-gradient-to-r from-[#1db227] to-[rgba(29,178,39,0)]'>
                                        <div className='flex flex-row rounded-3xl m-[1px] p-8 gap-4 items-center bg-[#ecfaef]'>
                                            <img src={icons1} alt='good'/>
                                            <div className='grow shrink basis-0'><h3>Cross-chain streaming payment widget</h3></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <div className='rounded-3xl bg-gradient-to-r from-[#1db227] to-[rgba(29,178,39,0)]'>
                                        <div className='flex flex-row rounded-3xl m-[1px] p-8 gap-4 items-center bg-[#ecfaef]'>
                                            <img src={icons1} alt='good'/>
                                            <div className='grow shrink basis-0'><h3>No-code widget configuration UI</h3></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-2 border-custom rounded-full h-8 w-8 bg-white absolute left-0  mt-5"></div>
                        <div className="ml-12 p-4 border-l-2 border-custom border-dashed  text-left">
                            <div className='space-y-2'>
                            <h3 className="font-semibold text-2xl mb-1">Phase 2</h3>
                                <div className='flex flex-col gap-5'>
                                    <div className='rounded-3xl bg-[#eaeff4]'>
                                        <div className='flex flex-row rounded-3xl m-[1px] p-8 gap-4 items-center '>
                                            <img src={icons2} alt='good'/>
                                            <div className='grow shrink basis-0'><h3>Purpose-built Merchant Dashboard</h3></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <div className='rounded-3xl bg-[#eaeff4]'>
                                        <div className='flex flex-row rounded-3xl m-[1px] p-8 gap-4 items-center '>
                                            <img src={icons2} alt='good'/>
                                            <div className='grow shrink basis-0'><h3>Cross-chain Stream-gating NFTs</h3></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <div className='rounded-3xl bg-[#eaeff4]'>
                                        <div className='flex flex-row rounded-3xl m-[1px] p-8 gap-4 items-center '>
                                            <img src={icons2} alt='good'/>
                                            <div className='grow shrink basis-0'><h3>Cross-chain gated API recipes</h3></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Roadmap