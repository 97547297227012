import React from 'react'
// import svg1 from './../assets/images/hero_img_cdd5351b7a.svg'
import { Customization, Scaling, Action, Footer, Needs, Roadmap } from '../Components'

const LearnMore = () => {
  return (
    <section className='m-0 container mx-auto px-4 lg:px-24  min-h-screen flex  flex-col justify-center items-center text-center space-y-20 relative '>
      <div className=' text-center mx-0 lg:mx-10 px-0 lg:px-20'>
        <h2 className=' sm:leading-5 text-3xl lg:text-6xl font-bold text-[#5B6474] tracking-tight mx-0  '>
            Create cross-chain subscription revenue in seconds.
        </h2>
        <h3 className=' text-base font-medium lg:py-4 lg:font-thin lg:text-base m-0 leading-8 lg:leading-none'>
          Build a state-of-the-art cross chain crypto subscription experience now.
          Get exponential conversion and retention.
        </h3>
        <div className='py-10 mb-20 flex flex-col justify-center items-center space-y-3 text-white'>
          {/* <a href='launch' target='_blank' > */}
              
              <button className=' border border-custom rounded-full px-8 py-3 text-center text-sm font-bold lg:w-fit select-none text-custom'>
                  Book a Demo
              </button>
          {/* </a> */}
        </div>
        <img src={require("../assets/images/output_image (1).jpeg")} alt='my svg'/>
      </div>
        <Customization/>
        <Scaling/>
        <Action/>
        <div className=' mx-0 w-screen'>
          <Needs/>
          <Roadmap/>
        </div>
        <Footer/>
    </section>
  )
}

export default LearnMore