import React from 'react'
import CardType4 from './Cards/CardType4'

const Customization = () => {
    const build = require('./../assets/images/output_image.png')
    const expor = require('./../assets/images/output_image (2).jpeg')
    const integrate = require('./../assets/images/output_image (3).jpeg')
  return (
    <section className='my-14 mx-2'>
        <div className='mb-16 '>    
            <h2 className=' sm:leading-5 text-3xl lg:text-6xl font-bold text-[#5B6474] tracking-wide m-0 mx-3 lg:mx-0'>
                Customize and integrate your checkouts in minutes.
            </h2>
            <h3 className=' text-base font-normal my-5 lg:py-4 lg:font-normal lg:text-lg m-0 px-0 lg:px-20  leading-7'>
            Start accepting cross-chain crypto subscription in 3 easy steps. Customize your cross-chain checkout widget, export it as JSON or publish it to IPFS for a hosted link, and integrate it into your platform.
            </h3>
        </div>
        <div className='flex flex-col lg:flex-row gap-[1.5rem] items-stretch'>
            <CardType4
                title={'Build Widget'}
                subTitle={'Customize every detail'}
                image={build}
            />
            <CardType4
                title={'Export'}
                subTitle={'Select most convinent option'}
                image={expor}
            />
            <CardType4
                title={'Integrate'}
                subTitle={'Recieve cross-chain payment second-by-second'}
                image={integrate}
            />
        </div>
        <div className='rounded-[30px] bg-white w-full mt-12 space-y-10 py-10 px-4'>
            <h1 className=' sm:leading-5 text-2xl lg:text-2xl font-medium text-black tracking-tight px-6'>Watch your revenue grow in real-time.</h1>
            <h2 className='text-xl lg:text-2xl'>
            With a single transaction, Maji Subscriptions flow by the second
            into your wallet, reducing transactions costs and unlocking immediate
            capital availability.
            </h2>
        
        </div>
    </section>
  )
}

export default Customization