import React from 'react'

const CardType1 = ({title, description, icons}) => {
    // const icons = require('./../../assets/images/Color logo - no background.png')
  return (
    <section className='flex flex-col rounded-md lg:w-1/3 p-11' style={{backgroundColor:'#b4c4cc'}}>
        <section >
            <img src={icons} alt='icons' className='w-28 select-none'/>
        </section>
        <section className=''>
            <div>
                <h3 className='text-2xl lg:text-xl font-semibold lg:font-bold my-4'>
                    {title}
                </h3>
            </div>
            <div>
                <p className='text-xl md:text-lg lg:text-base font-normal leading-6'>{description}</p>
            </div>
        </section>
    </section>
  )
}

export default CardType1