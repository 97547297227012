import React from 'react'
import { Link } from 'react-router-dom'

const CardType3 = ({title, description}) => {
    const icons = require('./../../assets/images/IMAGE 6.png')

  return (
    <section className='flex flex-col lg:w-1/5'>
        <section className='p-6'>
            <img src={icons} alt='icons' className='w-28 select-none'/>
        </section>
        <section className='rounded-md p-8 lg:relative h-full' style={{backgroundColor:'#b4c4cc'}}>
            <div>
                <h3 className='text-2xl lg:text-xl font-semibold lg:font-bold my-4'>
                    {title}
                </h3>
            </div>
            <div>
                <p className='text-xl md:text-lg lg:text-base font-normal leading-6 pb-6'>{description}</p>
            </div>
        {/* <div className='flex items-center justify-start'> */}
            {/* <Link className='lg:absolute  flex items-center justify-center border rounded-full px-8 py-3 text-center text-sm font-semibold sm:w-full lg:w-1/6 bottom-6  border-custom'to={'/try-now'}> */}
            <Link className='lg:absolute flex items-center justify-center b rounded-full px-8 py-3 text-center text-sm font-semibold w-[95%] lg:w-[78%] bottom-0 lg:bottom-6  text-slate-200 bg-custom'to={'/try-now'}>
                Try Maji now
            </Link>
        {/* </div> */}
        </section>
    </section>
  )
}

export default CardType3