import React from 'react'

const Scaling = () => {
    const hero = require('./../assets/images/102.png')
  return (
    <section className='flex flex-col items-center m-auto my-14 space-y-4 '>
        <p className='text-custom pt-4 pb-2 text-base font-medium'>Built for scaling</p>
        <h1 className='leading-6 lg:leading-tight text-2xl lg:text-5xl font-black tracking-normal m-0 px-6 lg:px-12'>Grow your business with cross-chain frictionless subscriptions.</h1>
        <h2 className='px-4 lg:px-18 text-base lg:text-xl font-medium pb-4'>
        In as little as 3 clicks, users can subscribe to your web3 product or service with cross-chain money streams. 
        Cross-chain money streams flow in perpetuity, mitigating non-payment risk and reducing churn

        </h2>
        <img
          loading='lazy'
          src={hero}
          alt='scale'
          className='mt-5 w-fit lg:w-2/5 block'
        />
    </section>
  )
}

export default Scaling