import React from 'react'

const CardType4 = ({title, subTitle, image}) => {
  return (
    <section className='flex flex-col rounded-[30px] lg:w-1/3 pt-8 px-6' style={{backgroundColor:'#ffffff'}}>
        <section className=''>
            <div>
                <h3 className='text-2xl lg:text-xl font-semibold lg:font-bold my-1'>
                    {title}
                </h3>
                <h4 className='text-base lg:text-base font-normal lg:font-normal my-1'>{subTitle}</h4>
            </div>
            <div>
                <img src={image} alt='pic'/>
            </div>
        </section>
    </section>
  )
}

export default CardType4