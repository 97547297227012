import React from 'react'
import CardType1 from './Cards/CardType1'

const Introduction = () => {
    const sup = require('./../assets/images/Color logo - no background.png')
  return (
    <section className=''>
        <section className='pb-6 m-0'>
            <div className='m-0'>
                <h2 className=' sm:leading-5 text-6xl lg:text-8xl font-bold  tracking-tight m-0 '>
                    Introducing
                    <span>
                        <span className='bg-gradient-to-r  bg-clip-text'> MAJI</span>
                        <sup className='bg-center inline-block bg-contain w-40 select-none'>
                            <img src={sup} alt='sup logo'/>
                        </sup>
                    </span>
                </h2>
            </div>
            <h3 className=' text-2xl font-medium my-5 lg:py-4 lg:font-normal lg:text-3xl m-0'>
                Cross-Chain Streaming Infrastructure protocol
            </h3>
        </section>
        <section className='flex flex-col lg:flex-row gap-[1.5rem] items-stretch'>
            <CardType1
                icons={require('../assets/images/IMAGE 2.jpg')}
                title={'Real-time cross-chain Web3 Finance'}
                description={'Maji brings cross-chain micro-payments, subscriptions, salaries, vesting, and rewards to crypto-native entities and businesses.'}/>
            <CardType1
                icons={require('../assets/images/IMAGE 3.jpg')}
                title={'Cross-chain Streaming'}
                description={'We bring cross-chain real-time Web3 finance by enabling Programmable Cashflows that Handle a composable stream of value, with continuous settlement and per-second netting for extreme capital efficiency.'}/>
            <CardType1
                icons={require('../assets/images/IMAGE 5.jpg')}
                title={'Build with Maji cross-chain streams'}
                description={'With Maji, you will open a cross-chain stream with a single line of code. Plus, thanks to our SDK Suite and Protocol Docs, you\'ll have everything you need to integrate cross-chain asset streaming into your dapps.'}/>
        </section>
        <div className='pt-10 mb-0'>
            <a href='https://blocksurvey.io/maji-early-access-dqn91xjKQi6ymmOeMXUrKQ?v=o'>
               <button className='border rounded-full text-slate-200 px-8 py-3 text-center text-base font-bold sm:w-full lg:w-fit cursor-default  bg-custom justify-center items-center'>
                    REQUEST BETA
                </button>
            </a>
        </div>

    </section>
  )
}

export default Introduction