import React from 'react'

const Trynow = () => {
  const majiTry = require('./../assets/images/output_image.png')
  const logo = require('./../assets/images/Color logo - no background.png')
  return (
    <section className='m-0 container mx-auto max-w-7xl min-h-screen'>
      <div className='flex flex-col lg:flex-row py-6 pb-10 border rounded-lg'>
        <section className='w-full lg:w-1/2 '>
          <h3 className='text-custom py-6 text-sm'>________  Cross-chain Subscription checkout builder</h3>
          <h1 className='text-3xl font-bold tracking-normal pb-6'> Build your cross-chain checkout and get paid every second, minute, hour, day or month</h1>
          <p>
            Define your cross-chain payment details and experiment with component styles. Export your creation with ease in the most convenience format and seamlessly integrate in into your platform  
          </p>
          <div className='pt-10 mb-0 space-x-4 '>
            <a href='https://blocksurvey.io/maji-early-access-dqn91xjKQi6ymmOeMXUrKQ?v=o'>
               <button className=' border border-custom bg-custom rounded-lg px-8 py-3 text-center text-sm font-semibold lg:w-fit select-none'>
                    REQUEST BETA
                </button>
            </a>
                <button className=' border border-custom rounded-lg px-8 py-3 text-center text-sm font-semibold lg:w-fit select-none'>
                   Book a Demo
                </button>
          </div>
        </section>
        <figure className='w-full lg:w-1/2 my-10 lg:m-0'>
          <img src={majiTry} alt='majiTry'/>
        </figure>
      </div>
      <div className='flex lg:flex-row justify-between w-full space-x-8 my-10'>
        <figure className=''>
          <img src={logo} alt='logo' className='w-28 lg:w-20'/>
        </figure> 
        <div className=' right-full left-0 space-y-4'>
          <h2 className='text-lg font-bold'>Social media 
          </h2>
          <div>
            <ul className='space-y-2'>
              <li>Medium</li>
              <li>Discord</li>
              <li>Twitter</li>
              <li>LinkedIn</li>
            </ul>

          </div>
        </div>
      </div>

    </section>
  )
}

export default Trynow