import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Hompage, LearnMore, Trynow } from "./Pages";
import { Header } from "./Components";

function App() {
  return (
     <div className="overflow-x-auto font-GTWalsheimPro-Regular text-base px-2 lg:px-6" style={{background:'#EBF9FA', color:'#2c344c'}}>
      <BrowserRouter>
          <Header/>
          <Routes>
            <Route path="/" element={<Hompage/>}/>
            <Route path="/learn-more" element={<LearnMore/>}/>
            <Route path="/try-now" element={<Trynow/>}/>
        </Routes>
      </BrowserRouter>
      
     </div>
  );
}

export default App;
