import React from 'react'
import CardType2 from './Cards/CardType2'
import CardType3 from './Cards/CardType3'
import { Link } from 'react-router-dom'
const Usecases = () => {
  return (
    <section className='py-16 lg:py-36'>
        <h1 className='sm:leading-5 text-4xl lg:text-6xl font-medium  tracking-tight m-0 pb-4'>USE CASES</h1>
        <section className='flex flex-col lg:flex-row gap-[1.5rem] items-stretch'>
            <CardType3
                icons={require('../assets/images/IMAGE 6.png')}
                title={'Cross-chain Streaming on-chain subscriptions'}
                description={'Enable users to pay for their web3 subscription with ongoing cross-chain streams instead of recurring transactions.\n'
                    +
                'Maji enables you to create on-chain payment streaming and subscriptions in a cross-chain environment.'}
            />
            <CardType2
                icons={require('../assets/images/IMAGE 1.jpg')}
                title={'Cross-Chain Disperse and collection '}
                description={'Enable users to disperse and collect funds to multiple chains in a single streaming transaction by specifying addresses, chains, and amounts, using Maji.\nPay and get paid every second in a cross-chain'}
            />
            <CardType2
                icons={require('../assets/images/IMAGE 7.png')}
                title={'Cross-chain Streaming real-time DEX & DCA Protocols'}
                description={'Maji cross-chain streams can be used within DeFi and DEXes and to create new types of passive and retail-friendly investment experiences.\n'+
                'Instead of manually triggering multiple transactions in order to follow the market, users can exchange assets based on a hands-off constant stream in a cross-chain environment.'}
            />
            <CardType2
                icons={require('../assets/images/IMAGE 4.jpg')}
                title={'Cross-chain Streaming & NFTs'}
                description={'Maji & NFTs go well together!\n'+
                'Users can make use of smart contract wallets to enable NFT rentals with cross-chain money streams. This workflow has many applications within web3 gaming & membership NFTs and is likely to find early adoption within niche NFT & gaming communities.'}
            />
            <CardType2
                icons={require('../assets/images/IMAGE 8.jpg')}
                title={'Build a cross-chain asset streaming dApp'}
                description={'Maji Protocol will be modular and open source, meaning you can use it to build anything you want!'}
            />
        </section>
        <div className='flex flex-col gap-3 m-8 lg:mr-52'>
               {/* <Link className='border rounded-full px-8 py-3 text-center text-sm font-semibold sm:w-full lg:w-1/6  select-none border-custom'to={'/try-now'}>
                    Try Maji now
                </Link> */}
                <Link className='border rounded-full px-8 py-3 text-center text-sm font-semibold sm:w-full lg:w-[17.5%]  select-none border-custom'to={'/learn-more'}>
                    Learn more
                </Link>
        </div>
    </section>
  )
}

export default Usecases