import React from 'react'
import { Body, Footer } from '../Components'
const Homepage = () => {
  return (
    <div className="m-0 container mx-auto max-w-7xl px-6 lg:px-2  cursor-default min-h-screen">
        <Body/>
        <Footer/>
    </div>
  )
}

export default Homepage