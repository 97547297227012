import React from 'react'

const CardType2 = ({title, description, icons}) => {
    // const icons = require('./../../assets/images/Color logo - no background.png')

  return (
    <section className='flex flex-col lg:w-1/5'>
        <section className='p-6'>
            <img src={icons} alt='icons' className='w-28 select-none'/>
        </section>
        <section className='rounded-md p-8 h-full' style={{backgroundColor:'#b4c4cc'}}>
            <div>
                <h3 className='text-2xl lg:text-xl font-semibold lg:font-bold my-4'>
                    {title}
                </h3>
            </div>
            <div>
                <p className='text-xl md:text-lg lg:text-base font-normal leading-6'>{description}</p>
            </div>
        </section>
    </section>
  )
}

export default CardType2