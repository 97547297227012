import React from 'react'

const Cardtype5 = ({img, title, subTitle }) => {
  return (
    <section className='rounded-3xl bg-white w-full lg:w-1/3 p-2 group my-[50px] text-left'>
        <div className='relative'>
        <img src={img} alt='' className='rounded-3xl'/>
        <div className='absolute flex justify-center items-center top-0 bottom-0 left-0 right-0 w-full h-full invisible group-hover:visible bg-[rgba(0,0,0,0.6)] rounded-3xl'>
            <button className=' border border-custom bg-custom rounded-full px-8 py-3 text-center text-sm font-bold lg:w-fit'>
                Check the Demo
            </button>
        </div>
        </div>
        <div className='p-4'>
          <h2 className='font-semibold text-xl'>{title}</h2>
          <h3 className=''>{subTitle}</h3>
        </div>
    </section>
  )
}

export default Cardtype5