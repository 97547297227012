import React from 'react'
import Cardtype5 from './Cards/Cardtype5'

const Action = () => {
  return (
    <section className='space-y-6'>
        <h1 className='sm:leading-5 text-5xl lg:text-5xl font-black text-gray-800 tracking-wide m-0 '>See it in action</h1>
        <h2 className='font-normal text-xl'>Try one of our interactive demos below.</h2>
        <div className='flex flex-col lg:flex-row gap-[1.5rem] items-stretch'>
            <Cardtype5 
                img={require('../assets/images/SUBSCRIPTION IMAGE SMALL PART.png')}
                title={'Web 3 Services & API'}
                subTitle={'Recurring payments simplified'}
            />
            <Cardtype5
                img={'https://strapi-website-assets.s3.eu-west-2.amazonaws.com/coming_soon_34363c91d4.svg'}
                title={'Donations and Creations'}
                subTitle={'Monetize your following'}
            />
            <Cardtype5
                img={'https://strapi-website-assets.s3.eu-west-2.amazonaws.com/coming_soon_34363c91d4.svg'}
                title={'DAOs and Communities'}
                subTitle={'Generate substantial revenue'}
            />
        </div>
    </section>
  )
}

export default Action