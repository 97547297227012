import React from 'react'
import { useLocation } from 'react-router-dom'

const Header = () => {
    const logo = require('./../assets/images/Color logo - no background.png')
    const logo2 = require('./../assets/images/Color logo with backgroundFull.png')
    const location = useLocation();
  return (
    <nav className='container mx-auto max-w-7xl py-10 px-10 cursor-default'>
        <section className='flex justify-between w-full items-center'>
            <figure>
                <img src={location.pathname === '/learn-more' ? logo2:logo} alt='logo' className=' block sm:w-20 w-20  md:w-20 lg:w-20'/>
            </figure>
            <figcaption >
              <a href={`${location.pathname === '/learn-more'? 'https://blocksurvey.io/maji-early-access-dqn91xjKQi6ymmOeMXUrKQ?v=o' : '#documentation'}`}>
                <button  className={`${location.pathname === '/learn-more' ? 'border border-custom bg-custom text-white rounded-full px-8 py-3 text-center text-sm font-semibold lg:w-fit':'border border-black rounded-lg px-1 lg:px-8 py-3 lg:py-3 text-center text-sm font-semibold sm:w-full lg:w-fit cursor-default select-none'}`}>
                  {location.pathname === '/learn-more' ? 'REQUEST BETA':'Documentation (Soon)'}
                </button>
              </a>
            </figcaption>

        </section>
        
    </nav>
  )
}

export default Header